// Gatsby requirements
import React from "react"
import { graphql } from "gatsby"

// Storyblok preview
import { useStoryblokState, storyblokEditable, storyblokInit } from "gatsby-source-storyblok"

// Components
import Header from "components/Layout/Header/Header"


storyblokInit({
    accessToken: process.env.GATSBY_STORYBLOK_ACCESS_TOKEN,
});

const HeaderPreview = ({ data }) =>  {

    let story = useStoryblokState(data.storyblokEntry);

    return (
        <div {...storyblokEditable(story.content)}>
            <Header previewData={story} key={story._uid} />
            <div style={{height: '100vh', backgroundColor: 'var(--dark-blue)', width: '100%', display: 'block'}} />
        </div>
    )
}

export const Head = () => (
    <>
      <title>Header Preview</title>
      <meta name="robots" content="noindex"/>
    </>
)

export const query = graphql`
  query {
    storyblokEntry(full_slug: { eq: "site-configuration/main-header" }) {
        content
        id
        uuid
        internalId
    }
  }
`

export default HeaderPreview